import * as tslib_1 from "tslib";
import { HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngxs/store';
import { EMPTY, throwError } from 'rxjs';
import { catchError, filter, switchMap } from 'rxjs/operators';
import { isObject } from '../../live-auto-complete3/function-type/function/live-autocomplete-option-model-icon.function';
import { SwUpdatesService } from '../../service-worker/sw-update.service';
import { ROUTE_USER_LOGIN } from '../../user/route.paths';
import { UserLogoutAction } from '../../user/state/action/user-logout.action';
import { UserState } from '../../user/state/user.state';
import { isString } from '../../utils/type-guard/is-string';
import { ExpiredTokenError } from '../exception/expired-token.error';
import { HandledHttpError } from '../exception/handled-http.error';
import { NotFoundEndpointError } from '../exception/not-found-endpoint.error';
import { NotFoundInternetError } from '../exception/not-found-internet.error';
import { NotFoundServerError } from '../exception/not-found-server.error';
import { showTranslatedErrorDialog } from '../function/show-translated-error-dialog.function';
import { NotAuthorizedError } from '../exception/not-authorized.error';
var HttpErrorInterceptor = /** @class */ (function () {
    function HttpErrorInterceptor(translateService, store, router, swUpdatesService) {
        this.translateService = translateService;
        this.store = store;
        this.router = router;
        this.swUpdatesService = swUpdatesService;
        /**
         * ezzel tudjuk korlatozni hogy  a requestek ne induljanak el
         * @type {boolean}
         */
        this.cancelPendingRequests = false;
        this._disableErrorCodes = [];
    }
    Object.defineProperty(HttpErrorInterceptor.prototype, "disableErrorCodes", {
        get: function () {
            return this._disableErrorCodes;
        },
        enumerable: true,
        configurable: true
    });
    HttpErrorInterceptor.prototype.addDisableErrorCode = function (code) {
        this._disableErrorCodes.push(code);
    };
    HttpErrorInterceptor.prototype.removeDisableErrorCode = function (code) {
        if (this._disableErrorCodes.indexOf(code) > -1) {
            this._disableErrorCodes.splice(this._disableErrorCodes.indexOf(code), 1);
        }
    };
    HttpErrorInterceptor.prototype.intercept = function (req, next) {
        var _this = this;
        if (this.cancelPendingRequests) {
            return EMPTY;
        }
        var renewRun = this.store.selectSnapshot(UserState.renewRun);
        if (renewRun === false || req.url.startsWith('assets/i18n/') || req.url.endsWith('oauth/token')) {
            return this.getNextRequest(next, req);
        }
        req = req.clone({ headers: req.headers.set('ngsw-bypass', 'true') });
        return this.swUpdatesService.hasNewVersion$.pipe(filter(function (hasNewVersion) { return hasNewVersion === false; }), switchMap(function () { return _this.getNextRequest(next, req); }));
    };
    HttpErrorInterceptor.prototype.getNextRequest = function (next, req) {
        var _this = this;
        return next.handle(req).pipe(catchError(function (error) {
            error = _this.detectHandleErrors(error);
            if (_this._disableErrorCodes.length > 0 && _this._disableErrorCodes.indexOf(error.status) > -1) {
                return throwError(error);
            }
            error = _this.handleHandledError(error);
            return throwError(error);
        }));
    };
    /**
     * kezeljuk az ismert hibakat a fejleckodok alapjan
     * @param error
     * @param _error
     */
    HttpErrorInterceptor.prototype.handleHandledError = function (error) {
        var _this = this;
        var clickCallback;
        var dialogTitleTranslateKey;
        var dialogMessageTranslateKey;
        var backdropClass;
        if (error instanceof ExpiredTokenError && this.router.url !== "/" + ROUTE_USER_LOGIN) {
            // this.cancelPendingRequests = true;
            clickCallback = function () {
                _this.store.dispatch(new UserLogoutAction(_this.translateService.instant('HTTP_HANDLED_ERROR.EXPIRED_TOKEN.RELOAD_OVERLAY_TEXT')));
            };
            dialogTitleTranslateKey = 'HTTP_HANDLED_ERROR.EXPIRED_TOKEN_DIALOG.TITLE';
            dialogMessageTranslateKey = 'HTTP_HANDLED_ERROR.EXPIRED_TOKEN_DIALOG.TEXT';
            backdropClass = 'error-backdrop';
            error = new HandledHttpError(error);
        }
        else if (error instanceof NotFoundServerError) {
            dialogMessageTranslateKey = 'HTTP_HANDLED_ERROR.NOT_FOUND_SERVER_DIALOG.TEXT';
            backdropClass = 'error-backdrop';
            error = new HandledHttpError(error);
        }
        else if (error instanceof NotFoundInternetError) {
            dialogMessageTranslateKey = 'HTTP_HANDLED_ERROR.NOT_FOUND_INTERNET_DIALOG.TEXT';
            backdropClass = 'error-backdrop';
            error = new HandledHttpError(error);
        }
        else if (error instanceof NotFoundEndpointError) {
            dialogMessageTranslateKey = 'HTTP_HANDLED_ERROR.NOT_FOUND_ENDPOINT_DIALOG.TEXT';
            error = new HandledHttpError(error);
        }
        else if (error instanceof NotAuthorizedError) {
            dialogMessageTranslateKey = 'HTTP_HANDLED_ERROR.NOT_AUTHORIZED_DIALOG.TEXT';
            error = new HandledHttpError(error);
        }
        if (clickCallback !== undefined || dialogTitleTranslateKey !== undefined || dialogMessageTranslateKey !== undefined) {
            showTranslatedErrorDialog(clickCallback, dialogTitleTranslateKey, dialogMessageTranslateKey, undefined, backdropClass);
        }
        return error;
    };
    /**
     * detectaljuk az ismert hibakat http hibakod alapjan
     * @param error
     */
    HttpErrorInterceptor.prototype.detectHandleErrors = function (error) {
        if (error.status === 0) {
            error = new NotFoundInternetError(error);
        }
        else if (error.status === 404) {
            error = new NotFoundEndpointError(error);
        }
        else if (error.status === 504) {
            error = new NotFoundServerError(error);
        }
        else if (error.status === 401) {
            error = new ExpiredTokenError(error);
        }
        else if (error.status === 403 &&
            isObject(error.error) &&
            isString(error.error.type) &&
            error.error.type === 'AuthorizationException') {
            error = new NotAuthorizedError(error);
        }
        else if (error.status === 500 && isObject(error.error) && isString(error.error.message) && error.error.message.startsWith('400 {')) {
            var messageJSON = JSON.parse(error.error.message.replace(/^400 /g, '').replace(/\r?\n|\r/g, ''));
            error = new HttpErrorResponse(tslib_1.__assign({}, error, { error: messageJSON }));
        }
        return error;
    };
    return HttpErrorInterceptor;
}());
export { HttpErrorInterceptor };
